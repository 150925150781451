.footer {
	width: 100%;
	color: var(--Primary);
	background-color: white;
	padding: 40px 120px 28px 120px;
	border-top: 1px solid #f2f2f2;

	@media screen and (--phone), screen and (--tablet) {
		padding: 40px 20px 28px;
	}

	& > .declaration {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin: 0;
		font-size: 14px;
		letter-spacing: 0.8px;
		color: #a0a0a0;
		margin-top: 8px;

		@media screen and (--phone), screen and (--tablet) {
			display: flex;
			flex-direction: column-reverse;
			justify-content: center;
			align-items: flex-start;

			& > p {
				font-size: 12px;
				line-height: 18px;
			}
		}

		& a {
			font-size: 14px;
			letter-spacing: 0.8px;
			color: #a0a0a0;
		}

		& .declaration-content {
			align-self: flex-end;

			@media screen and (--phone), screen and (--tablet) {
				margin-top: 38px;
				align-self: flex-start;
				font-size: 12px;
				letter-spacing: 0.6px;
			}
		}

		& .agency-detail {
			opacity: 0;
			text-align: right;
			font-size: 12px;

			& > p {
				margin-bottom: 4px;
			}

			@media screen and (--phone), screen and (--tablet) {
				margin-top: 50px;
				text-align: left;
			}
		}

		& .agency-detail.hide-agency-detail {
			visibility: hidden;
			pointer-events: none;
		}
	}

	& > .body {
		display: flex;
		flex-direction: column;
		margin: 0;

		@media screen and (--phone), screen and (--tablet) {
			display: block;
			margin-bottom: 32px;
		}

		& .icon-and-privacy {
			display: flex;
			margin-bottom: 28px;

			@media screen and (--phone), screen and (--tablet) {
				margin-bottom: 12px;
			}

			& .logo {
				width: 136px;
				height: 50px;

				@media screen and (--phone), screen and (--tablet) {
					margin-bottom: 0;
				}
			}

			& .social-media {
				display: flex;
				justify-content: flex-end;
				width: 20%;
				margin-left: auto;

				@media screen and (--phone), screen and (--tablet) {
					padding: 32px 0;
					justify-content: flex-start;
				}

				& > a {
					margin-left: 24px;

					@media screen and (--phone), screen and (--tablet) {
						margin-left: 0;
						margin-right: 24px;
					}
				}
			}
		}
	}

	& .content-container {
		display: flex;
		justify-content: space-between;

		& .content {
			width: 70%;

			@media screen and (--phone), screen and (--tablet) {
				width: 100%;
			}
		}

		& .terms-content {
			display: flex;
			flex-direction: column;
			gap: 50px;
			text-align: right;
			letter-spacing: 0.7px;
			color: #a0a0a0;
			font-size: 12px;
		}

		& .links-container {
			display: flex;
			flex-direction: column;

			& .links {
				display: flex;
				justify-content: flex-end;
				align-items: center;
				height: 16px;

				& a {
					font-size: 14px;
					letter-spacing: 0.8px;
					color: #a0a0a0;

					@media screen and (--phone), screen and (--tablet) {
						font-size: 12px;
						letter-spacing: 0.2px;
					}
				}

				@media screen and (--phone), screen and (--tablet) {
					margin-bottom: 12px;
					margin-top: 32px;
					justify-content: flex-start;
				}

				& > .division {
					margin: 0 16px;

					@media screen and (--phone), screen and (--tablet) {
						margin: 0 16px;
					}
				}
			}
		}

		@media screen and (--phone), screen and (--tablet) {
			display: flex;
			flex-direction: column;
		}
	}
}

.footer-navigators.desktop {
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;

	& > .item {
		&:not(:last-child) {
			margin-right: 40px;
		}

		& .title {
			display: block;
			font-size: 12px;
			letter-spacing: 0.8px;
			color: var(--Primary);
			margin-bottom: 16px;
		}

		& ul {
			margin: 0;
			padding: 0;
		}

		& li {
			list-style: none;
			margin-bottom: 16px;

			& a {
				font-size: 14px;
				letter-spacing: 0.8px;
				color: var(--Dark);
			}
		}
	}
}

.footer-navigators.mobile {
	display: flex;
	flex-direction: column;
	position: relative;
	height: 100%;
	width: calc(100% + 36px);
	margin: 0 -16px 0 -20px;

	& .title {
		color: #a0a0a0;
		font-size: 12px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
		letter-spacing: 0.2px;
	}
}
